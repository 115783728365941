import React from 'react';
import {isMobile, isIOS} from 'react-device-detect';

declare global {
    interface Window { ga: any; }
}

const LandingPage: React.FC = () => {

    const store_ios: any     = isMobile ? process.env.REACT_APP_STORES_IOS_MOBILE_URL : process.env.REACT_APP_STORES_IOS_WEB_URL;
    const store_android: any = isMobile ? process.env.REACT_APP_STORES_ANDROID_MOBILE_URL : process.env.REACT_APP_STORES_ANDROID_WEB_URL;
    const store_hauwei: any  = isMobile ? process.env.REACT_APP_STORES_HUAWEI_MOBILE_URL : process.env.REACT_APP_STORES_HUAWEI_WEB_URL;

    const zapperUrl: any = isIOS ? process.env.REACT_APP_PROTOCOLS_IOS : process.env.REACT_APP_PROTOCOLS_ANDROID;
    const device: string = isMobile ? 'Mobile' : 'Desktop';

    const sendGoogleAnlyticsEvent = (category: string, action: string, label: string) => {
		window.ga('send','event', category, action, label)
	}

    if(isMobile){
        const storeName: string = isIOS ? 'App Store' : 'Play Store';
        sendGoogleAnlyticsEvent(device, 'Redirect', storeName);
        window.location.href = isIOS ? store_ios : store_android;
    }

    return (
    
        <div className="base text-base flex lg:h-screen">
            <div className="flex w-full">
                <div className="hidden lg:flex w-3/5 items-center justify-center relative bg-gradient bg-primaryBlue">
                    <div className="w-1/3">
                        <img src="img/zapper-device.png" alt="" />
                    </div>
                </div>
                <div className="w-full lg:w-2/5 flex items-center justify-center">
                    <div className="w-3/4 lg:w-2/4 flex flex-col self-center my-16">
                        <div className="ie-svg-fix">
                            <img src="img/zapper-app-icon-rounded.svg" className="mx-auto" alt="" />
                        </div>
                        <h1 className="text-4xl text-primaryBlue font-bold leading-10 text-center mt-10 mb-12">Get the free<br />Zapper app<br />today</h1>
                        <a href={store_ios} onClick={() => {sendGoogleAnlyticsEvent(device, 'Click', 'App Store')}}><img src="img/badge_app_store.svg" alt="" className="mx-auto mb-5" /></a>
                        <a href={store_android} onClick={() => {sendGoogleAnlyticsEvent(device, 'Click', 'Play Store')}}><img src="img/badge_play_store.svg" alt="" className="mx-auto mb-5" /></a>
                        <a href={store_hauwei} onClick={() => {sendGoogleAnlyticsEvent(device, 'Click', 'App Gallery')}}><img src="img/badge_app_gallery.svg" alt="" className="mx-auto" /></a>
                        <p className="text-center lg:hidden mt-10">Already have the Zapper app?
                            <br /><a href={zapperUrl} onClick={() => {sendGoogleAnlyticsEvent(device, 'Click', 'Open Zapper')}} className="font-bold">Open now</a>.
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default LandingPage;